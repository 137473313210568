import React, { useState, useEffect, useRef, useContext } from 'react'
import { useForm } from "react-hook-form"
import Button from '../../components/button'
import Alert from '../../components/alert'
import { useUser } from '../../hooks/useUser'
import { useMe } from '../../hooks/useMe'
import { useApi } from '../../hooks/useApi'
import { navigate } from "gatsby"
import UiStoreContext from '../../contexts/uiStoreContext'
import 'twin.macro'
import AccountLayout from './components/accountSettingsLayout'
import {
	faSave,
	faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { toast, Zoom } from 'react-toastify'

const AccountDeletion = ({...props}) => {

	const { callApi } = useApi()
	const { user, login, logout } = useUser()
	const { me } = useMe() 
	const { register, errors, reset, watch, formState, handleSubmit } = useForm({
		// mode: 'onChange'
	})
	const [error, setError] = useState()
	const uiStore = useContext(UiStoreContext)
	const [spin, setSpin] = useState(false)
	const { isDirty, isValid} = formState

	const onSubmit = async (data) => {
		try {
			setSpin(true)
			setError(null)
			await login(user.email, data.password)
			await callApi({
				method: 'delete',
				namespace: 'me'
			})
			toast.info('Account deleted successfully!', {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				transition: Zoom
			});
			await logout()
			resetFormAndError()
		}
		catch (err) {
			if (err.response && err.response.data) {
				setError(err.response.data);
			}
			else {
				// console.log(err)
				setError(err);
			}
		}
		finally {
			setSpin(false)
		}
	};

	const resetFormAndError = (e) => {
		setError(null)
		reset()
	}

	return <AccountLayout tab="accountdeletion" {...props}>
		{ me && me.paymentSubscriptionId && <div>
			In order to delete your account, you must first cancel your paid plan subscription. You can do so by <a href="/account/plan">accessing your subscription portal here</a>.
		</div>}
		{ me && !me.paymentSubscriptionId && <div tw="">
			<p>If you wish to completely delete your file.io account, including all of your files and settings, you can do so by submitting the form below.</p>
			<Alert>Attention! By clicking the button below your file.io account including all of your files will be permanently deleted and cannot be recovered under any circumstances.</Alert>
			<form tw="mt-4" onSubmit={handleSubmit(onSubmit)}>
				<label htmlFor="password">Password</label>
				<input
					name="password"
					type="password"
					placeholder="Enter your password"
					ref={register({
						required:'Password is required'
					})}
				></input>
				{errors.password && <div className="error">{errors.password.message}</div>}
				<label htmlFor="deleteMe">Type "delete everything forever" in this box</label>
				<input 
					name="deleteMe"
					type="text"
					placeholder={`Type "delete everything forever" here`}
					ref={register({
						required: 'Confirmation is required',
						validate: value => value === "delete everything forever" || 'Confirmation is not correct'
					})}
				/>
				{errors.deleteMe && <div className="error">{errors.deleteMe.message}</div>}

				{error && <div tw="my-4!" className="error">{error.message || 'An error occurred.'}</div>}

				{isDirty}{isValid}
				<div tw="flex flex-col md:flex-row-reverse mt-4">
					<Button icon={faSave} type="submit" special spin={spin}>Delete My Account</Button>
					<Button icon={faTimes} type="button" tw="mt-2 md:mt-0 md:mr-2" onClick={resetFormAndError}>Cancel</Button>
				</div>
			</form>
		</div>}
	</AccountLayout>
};

export default AccountDeletion