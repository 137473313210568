import React from "react"
import Icon from "./icon"
import {
	faExclamationCircle
} from '@fortawesome/free-solid-svg-icons'
import "twin.macro"

const Alert = ({children, ...props}) => {
	return <div tw="p-4 bg-viking-100 rounded-md flex justify-items-center" {...props}>
		<Icon icon={faExclamationCircle} tw="block w-10 ml-2 mr-6 text-viking flex-shrink-0"/>
		<div>{children}</div>
	</div>
}

export default Alert